// Pattern
// provided and maintained by CD Apps UX
// -------------------------------------
// copyright (c) 2016 Watchtower Bible & Tract Society of New York, Inc.

// -----------------
// Palette
// -----------------

// Generates individual color classes for the primary, accent, and gray palettes
// ex: '.p300' is the 300 intensity of the primary color
// and '.bg-p300' is a background of the 300 intensity of the primary color
// alternatively '.a300' is the 300 intensity of the accent color
// and '.g300' is the 300 intensity of gray

// Note that the 'fill' property is used for SVG icons
// and otherwise has no effect.

@each $color-intensity, $val in $ptrn-primary {
  .bg-p#{$color-intensity} {
        background-color: $val !important;
  }

  .p#{$color-intensity} {
        color: $val !important;
  }

  .p#{$color-intensity} > svg { fill: $val !important; }
  .p#{$color-intensity}-stroke > svg { stroke: $val !important; }
}

@each $color-intensity, $val in $ptrn-accent {
  .bg-a#{$color-intensity} {
        background-color: $val !important;
  }

  .a#{$color-intensity} {
        color: $val !important;
  }

  .a#{$color-intensity} > svg { fill: $val !important; }
  .a#{$color-intensity}-stroke > svg { stroke: $val !important; }
}

@each $color-intensity, $val in $ptrn-gray {
  .bg-g#{$color-intensity} {
        background-color: $val !important;
  }

  .g#{$color-intensity} {
        color: $val !important;
  }

  .g#{$color-intensity} > svg { fill: $val !important; }
  .g#{$color-intensity}-stroke > svg { stroke: $val !important; }
}

// Not used: However you can output a CSS class for EVERY color available in all the palettes
// This is not recommended as good UX practice for CD Apps. Your color choices should be
// primarily limited to variations within the primary, accent, and gray palettes.
//@each $color, $color-map in $colors {
//   @each $color-intensity, $val in $color-map {
//      .bg-#{$color}#{$color-intensity} {
//         background-color: $val;
//      }
//      .#{$color}#{$color-intensity} {
//         color: $val !important;
//      }
//   }
//}
