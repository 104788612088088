/*
* Sidebar
*/

.sidebar {
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky; /* Behind the navbar */
    padding: 1rem 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    transition: all 0.3s;
    min-width: 0px;
    width: 220px;
    max-width: 00px;
    transition: min-width 0.3s ease, max-width 0.3s ease;
    
    &>*{
        transition: 0s;
        opacity: 0;
        height: 0;
    }

    & .nav-link {
        font-weight: 400;
        font-size: 1rem;
        padding: .25rem 1.25rem;
        border-left: 5px $light solid;
    }
    
    & .nav-link.active {
        background-color: darken($light, 10%);
        border-left: 5px $primary solid;
    }
    
    & span.nav-link {
        font-weight: 500;
    }
    & a.nav-link:hover {
        text-decoration: none;
        background-color: darken($light, 10%);
        border-left: 5px darken($light, 10%) solid;
    }
    & .nav-link.active:hover {
        text-decoration: none;
        background-color: darken($light, 10%);
        border-left: 5px $primary solid;
    }
    & .nav-link.nav-hdr {
        font-weight: 500;
    }

    & .nc-icon{
        font-weight: 900;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 64px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    padding-bottom: 3rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar-heading {
    font-size: 1rem;
    text-transform: uppercase;

    /*& a {
        color: $secondary;
    }*/
}

.sidebar-shown {
    flex-wrap: nowrap;
}

.sidebar-shown .sidebar{
    display: block;
    min-width: 220px;
    width: 220px;
    max-width: 220px;

    &>*{
        opacity: 1;
        height: auto;
    }
}
/*
* Content
*/
#sidebar-toggle{
    @media (min-width: 400px){
        margin-left: 1rem;
    }
    transition: margin-left 0.3s ease;
    &.sidebar-hidden{
        margin-left: -0.25rem;
    }
}


.sidebar-backdrop {
    display: none;
}

[role="main"] {
    padding-top: 75px; /* Space for fixed navbar */
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    & > .bg-white {
        padding: 1rem;
    }
    & > * {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 1;
    }

    & > .bg-white {
        flex-grow: 1;
        flex-shrink: 0;
    }
}

@media (max-width: 1000px){
    .sidebar-shown .sidebar-backdrop {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-sticky - 10;
        width: 100vw;
        height: 100vh;
        background-color: $modal-backdrop-bg;
        opacity: $modal-backdrop-opacity;
    }
    .sidebar {
        position: fixed;
        padding: 64px 0 0; /* Height of navbar */
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        
    }
    body.sidebar-is-shown{
        overflow: hidden;
    }

    .nb-cond-fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $zindex-fixed;
      }
}
@media (min-width: 1000.1px){
    .sidebar-shown [role="main"] {
       // padding-left: 230px ;
       max-width: calc( 100vw - 222px);
       width: calc( 100vw - 222px);
    }
    [role="main"] {
        padding-top: 1rem; /* Space for fixed navbar */
        min-height: calc( 100vh - 64px );
    }
   
}

@media print {
    .sidebar-shown .sidebar-backdrop, .sidebar {
        display: none !important;
    }
}

.dropdown-menu li a .nc-icon {
    font-weight: 800;
}
