.fullscreen-icon { font-family: 'FontAwesome'; font-style: normal; font-weight: normal; font-size:12pt;}
.fullscreen-icon:before{ content: "\F065";}
.fullscreen-icon.leaflet-fullscreen-on { background-position:0 -26px; }
.leaflet-touch .fullscreen-icon { background-position: 2px 2px; }
.leaflet-touch .fullscreen-icon.leaflet-fullscreen-on { background-position: 2px -24px; }
/* one selector per rule as explained here : http://www.sitepoint.com/html5-full-screen-api/ */
.leaflet-container:-webkit-full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:-ms-fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-pseudo-fullscreen { position: fixed !important; width: 100% !important; height: 100% !important; top: 0px !important; left: 0px !important; z-index: 99999; }