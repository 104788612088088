
/* style for termine */
$closed-bg:          #e0e0e0;
$selected-bg:        $warning;
$selected-color:     #FFF;
$declined-bg:        $danger;
$declined-color:     #ffffff;
$accepted-bg:        $success;
$accepted-color:     #ffffff;

$open-border-color:  #eeeeee;
$other-border-color: #ffffff;

$color-evt1:         #5391ca;
$color-evt2:         #61b3a3;
$color-default:      #0373b3;

#termine th{
 background-color: #DDDDDD;
 border-color: #FFFFFF;
}


#termine .open {
	cursor: pointer !important;
	
}

.badge.open {
  background-color: #ffffff;
  color: $gray-800;
  border: 1px solid $gray-800;
}

.badge{
  &.open, &.closed, &.selectedapplication, &.accepted{
    font-weight: inherit;
  }
}

.closed {
	background-color: $closed-bg !important;
	cursor: default !important;
  color: $gray-800;
}

.selectedapplication{
	color: $selected-color !important; 
	background-color: $selected-bg !important;
}

.closed.selectedapplication{
	background-color:  $declined-bg !important;
	color: $declined-color !important;
}

.accepted {
	color: $accepted-color !important;
	cursor: default !important;
	background-color: $accepted-bg !important;	
}

.accepted.declined {
	color: $accepted-color !important;
	cursor: default !important;
	background-image: linear-gradient(45deg, $declined-bg 25%, $accepted-bg 25%, $accepted-bg 50%, $declined-bg 50%, $declined-bg 75%, $accepted-bg 75%, $accepted-bg);
  background-size: 20px 20px;	
}

.closed.selectedapplication.accepted {
	color: $accepted-color !important;
	cursor: default !important;
	background-color: $accepted-bg !important;
}

.ovr-selectedapplication{
	background-color: lighten($selected-bg,30%);
}

.ovr-closed.ovr-selectedapplication{
	background-color:  lighten($declined-bg,30%);
}

.ovr-accepted {
	background-color: lighten($accepted-bg,30%);	
}

.ovr-accepted.ovr-declined {	
  background-image: linear-gradient(45deg, lighten($declined-bg,30%) 25%, lighten($accepted-bg,30%) 25%, lighten($accepted-bg,30%) 50%, lighten($declined-bg,30%) 50%, lighten($declined-bg,30%) 75%, lighten($accepted-bg,30%) 75%, lighten($accepted-bg,30%));
  background-size: 20px 20px;
  
}

.ovr-closed.ovr-selectedapplication.ovr-accepted {
	background-color: lighten($accepted-bg,30%);
}

.ovr-rpt-missing{
  color: $declined-color;
	background-color:  $declined-bg;
}

#termine .open{
  border-bottom: 1px solid $open-border-color !important;
  border-top: 1px solid $open-border-color !important;
}

.closed, 
.selectedapplication, 
.accepted{
  border-bottom: 1px solid $other-border-color !important;
  border-top: 1px solid $other-border-color !important;
}


.cal-applied {
  color: $selected-color !important;
  background-color: $selected-bg !important;
  border-color: darken($selected-bg, 35%);
  border-width: 2px;
  border-style: dashed;
}
.cal-accepted {
  color: $accepted-color !important;
  background-color: $accepted-bg !important;
  border-color: $accepted-bg !important;
  border-width: 2px !important;
}

.cal-declined {
  color: $declined-color !important;
  background-color: $declined-bg !important;
  border-color: $declined-bg !important;
  border-width: 2px !important;
}

.cal-accepted.cal-declined {
  color: $declined-color !important;
  background-image: linear-gradient(45deg, $declined-bg 25%, $accepted-bg 25%, $accepted-bg 50%, $declined-bg 50%, $declined-bg 75%, $accepted-bg 75%, $accepted-bg);
  background-size: 20px 20px;
  border-color: $declined-bg;
  border-width: 2px;
  border-style: dashed;
}


#termine .open:first-child, 
#termine .closed:first-child, 
#termine .selectedapplication:first-child, 
#termine .closed.selectedapplication:first-child, 
#termine .accepted:first-child, 
#termine .closed.selectedapplication.accepted:first-child{
  border-top: none !important;
}

#termine .open:last-child, 
#termine .closed:last-child, 
#termine .selectedapplication:last-child, 
#termine .closed.selectedapplication:last-child, 
#termine .accepted:last-child, 
#termine .closed.selectedapplication.accepted:last-child{
  border-bottom: none !important;
}

@mixin event-views($event, $color, $txt-color) {

  .card-#{$event} {
    border-color: $color;
    > {
      .card-header {
        color: $txt-color;
        background-color: $color;
        border-color: $color;
        font-size: 13px;
        + .card-collapse > .card-body {
          border-top-color: $color;
        }
        .badge {
          color: $color;
          background-color: #ffffff;
        }
      }
      .card-footer + .card-collapse > .card-body {
        border-bottom-color: $color;
      }
    }
  }

  .list-group-item-#{$event} {
    color: #fff !important;
    background-color: $color !important;
  }

  a.list-group-item-#{$event}{
    color: #fff !important;
  }

  .cal-#{$event},
  .eventhdr-#{$event},
  .sel-evt option.sel-#{$event}, 
  .cal-badge-#{$event} .fc-event-dot,
  .cal-#{$event} .fc-event-title{
    color: $txt-color !important;
    background-color: $color !important;
  }

  .cal-badge-#{$event} {
    border-left: $color solid 16px !important;
    padding-left: 3px !important;
    cursor: pointer;
  }

  .btn-cal-#{$event} {
    @include button-variant($color, $color);
  }

}

@include event-views(  1, darken($color-evt1,  5%), #ffffff);
@include event-views(  2, darken($color-evt2, 10%), #ffffff);
@include event-views(  4, darken($color-evt1, 15%), #ffffff);
@include event-views(  8, darken($color-evt2, 15%), #ffffff);
@include event-views( 16, darken($color-evt1, 25%), #ffffff);
@include event-views( 32, darken($color-evt2, 25%), #ffffff);
@include event-views( 64, darken($color-evt1, 30%), #ffffff);
@include event-views(128, darken($color-evt2, 30%), #ffffff);
@include event-views(256, darken($color-evt1, 35%), #ffffff);
@include event-views(512, darken($color-evt2, 35%), #ffffff);

@include event-views(  default, $gray-100, $gray-800);
@include event-views(    allok, $success, #FFFFFF);
@include event-views(       ok, #61b3a3,      #FFFFFF);
@include event-views(     todo, $warning, #FFFFFF);
@include event-views(  newtodo, $danger,  #FFFFFF);

.cal-badge-transparent {
  border-left: transparent solid 16px !important;
  padding-left: 3px !important;
}

.tile-month{
  border-radius: 0;
  background-color: $gray-100;
  cursor:pointer; 
  float:left; 
  margin:5px; 
  height: 130px; 
  text-align: center; 
  vertical-align: middle; 
  padding: 10px; 
  width: 130px; 
  font-size:150%; 
  line-height: 50px; 
  color: $body-color !important;
}

.tile-week{
  border-radius: 0;
  background-color: $gray-100;
  cursor:pointer; 
  float:left; 
  margin:5px; 
  height: 130px; 
  text-align: center; 
  vertical-align: middle; 
  padding: 10px; 
  width: 130px; 
  font-size:150%; 
  color: $body-color !important;
}

.list-week-outer{
  margin-bottom: 8px;
  padding-right: 0;

  .list-week{
    cursor: pointer; 
    display: table; 
    table-layout: fixed; 
    text-decoration: none;

    .number{
      width:60px;
      height:60px;
      vertical-align: middle; 
      text-align: center; 
      font-size: 30px; 
      line-height: 60px; 
      background-color:#eee;
    }

    .desc{
      display: table-cell; 
      font-size: 16px; 
      color: #000; 
      width: 100%; 
      vertical-align: middle; 
      white-space: nowrap; 
      overflow: hidden; 
      -ms-text-overflow: ellipsis; 
      -o-text-overflow: ellipsis; 
      text-overflow: ellipsis; 
      border: 1px solid #eee; 
      padding-left:5px;
    }
  }
}

.col-sm-21 {
  margin-top: 10px;
}

@media (min-width: 768px){
  .col-sm-21 {
      width: 14.3%;
      padding: 0 !important;
      -ms-word-break: break-all;
          word-break: break-all;
      /* Non standard for webkit */
          word-break: break-word;

    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    hyphenate-character: "\2010";
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    padding-bottom: 10px !important;
    border: 1px solid #eee;
    border-radius: 0;
  }
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }

}
.eventhdr {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  
}
.schicht{
  display: block;
  border-radius: 4px;
}

#termine .open:hover{
  background-color: $open-border-color;
}

.eventsep{
  display: block;
  background-color: $open-border-color;
  width: auto;
  height: 5px;
  margin-left: -35px;
  margin-right: -35px;
}

@media (min-width: 768px){
  .eventsep{
    display: block;
    background-color: $open-border-color;
    width: auto;
    height: 10px;
    margin-left: -5px;
    margin-right: -5px;
  }
}

.cal-changed{
  color: $danger !important;
}
.cal-open{
  color: $warning !important;
}

.fc-today {
    background-color: lighten($color-default, 30%) !important;
}
