/*------------------------
	font icons
-------------------------*/

.nc-icon.nc-dashboard-half-2:before {
  content: "\ea04";
}

.nc-icon.nc-zip:before {
  content: "\ea06";
}

.nc-icon.nc-xml:before {
  content: "\ea07";
}

.nc-icon.nc-xlsx:before {
  content: "\ea08";
}

.nc-icon.nc-wav:before {
  content: "\ea09";
}

.nc-icon.nc-txt:before {
  content: "\ea0a";
}

.nc-icon.nc-ttf:before {
  content: "\ea0b";
}

.nc-icon.nc-tiff:before {
  content: "\ea0c";
}

.nc-icon.nc-tif:before {
  content: "\ea0d";
}

.nc-icon.nc-srt:before {
  content: "\ea0e";
}

.nc-icon.nc-sketch:before {
  content: "\ea0f";
}

.nc-icon.nc-sql:before {
  content: "\ea10";
}

.nc-icon.nc-rtf:before {
  content: "\ea11";
}

.nc-icon.nc-rss:before {
  content: "\ea12";
}

.nc-icon.nc-ruby:before {
  content: "\ea13";
}

.nc-icon.nc-raw:before {
  content: "\ea14";
}

.nc-icon.nc-rar:before {
  content: "\ea15";
}

.nc-icon.nc-py:before {
  content: "\ea16";
}

.nc-icon.nc-psd:before {
  content: "\ea17";
}

.nc-icon.nc-pptx:before {
  content: "\ea18";
}

.nc-icon.nc-pdf:before {
  content: "\ea19";
}

.nc-icon.nc-otf:before {
  content: "\ea1a";
}

.nc-icon.nc-obj:before {
  content: "\ea1b";
}

.nc-icon.nc-mpg:before {
  content: "\ea1c";
}

.nc-icon.nc-mp4:before {
  content: "\ea1d";
}

.nc-icon.nc-mp3:before {
  content: "\ea1e";
}

.nc-icon.nc-mov:before {
  content: "\ea1f";
}

.nc-icon.nc-mkv:before {
  content: "\ea20";
}

.nc-icon.nc-md:before {
  content: "\ea21";
}

.nc-icon.nc-max:before {
  content: "\ea22";
}

.nc-icon.nc-log:before {
  content: "\ea23";
}

.nc-icon.nc-jsp:before {
  content: "\ea24";
}

.nc-icon.nc-jar:before {
  content: "\ea25";
}

.nc-icon.nc-java:before {
  content: "\ea26";
}

.nc-icon.nc-jpg:before {
  content: "\ea27";
}

.nc-icon.nc-js:before {
  content: "\ea28";
}

.nc-icon.nc-iso:before {
  content: "\ea29";
}

.nc-icon.nc-ics:before {
  content: "\ea2a";
}

.nc-icon.nc-gzip:before {
  content: "\ea2b";
}

.nc-icon.nc-html:before {
  content: "\ea2c";
}

.nc-icon.nc-gpx:before {
  content: "\ea2d";
}

.nc-icon.nc-gif:before {
  content: "\ea2e";
}

.nc-icon.nc-font:before {
  content: "\ea2f";
}

.nc-icon.nc-flv:before {
  content: "\ea30";
}

.nc-icon.nc-flash:before {
  content: "\ea31";
}

.nc-icon.nc-exe:before {
  content: "\ea32";
}

.nc-icon.nc-epub:before {
  content: "\ea33";
}

.nc-icon.nc-eps:before {
  content: "\ea34";
}

.nc-icon.nc-docx:before {
  content: "\ea35";
}

.nc-icon.nc-doc:before {
  content: "\ea36";
}

.nc-icon.nc-dmg:before {
  content: "\ea37";
}

.nc-icon.nc-csv:before {
  content: "\ea38";
}

.nc-icon.nc-css:before {
  content: "\ea39";
}

.nc-icon.nc-csh:before {
  content: "\ea3a";
}

.nc-icon.nc-cgi:before {
  content: "\ea3b";
}

.nc-icon.nc-cfm:before {
  content: "\ea3c";
}

.nc-icon.nc-cad:before {
  content: "\ea3d";
}

.nc-icon.nc-bmp:before {
  content: "\ea3f";
}

.nc-icon.nc-avi:before {
  content: "\ea40";
}

.nc-icon.nc-aspx:before {
  content: "\ea41";
}

.nc-icon.nc-asp:before {
  content: "\ea42";
}

.nc-icon.nc-ase:before {
  content: "\ea43";
}

.nc-icon.nc-ai:before {
  content: "\ea44";
}

.nc-icon.nc-aac:before {
  content: "\ea45";
}

.nc-icon.nc-home-52:before {
  content: "\ea46";
}

.nc-icon.nc-folder-info:before {
  content: "\ea47";
}

.nc-icon.nc-chart-bars:before {
  content: "\ea48";
}

.nc-icon.nc-books:before {
  content: "\ea49";
}

.nc-icon.nc-stock:before {
  content: "\ea4a";
}

.nc-icon.nc-calendar-grid-58:before {
  content: "\ea4b";
}

.nc-icon.nc-calendar-add:before {
  content: "\ea4c";
}

.nc-icon.nc-calendar-check-59:before {
  content: "\ea4d";
}

.nc-icon.nc-send:before {
  content: "\ea4e";
}

.nc-icon.nc-email-85:before {
  content: "\ea4f";
}

.nc-icon.nc-reply-all:before {
  content: "\ea50";
}

.nc-icon.nc-reply:before {
  content: "\ea51";
}

.nc-icon.nc-attach-87:before {
  content: "\ea52";
}

.nc-icon.nc-edit:before {
  content: "\ea53";
}

.nc-icon.nc-curved-next:before {
  content: "\ea54";
}

.nc-icon.nc-trash-simple:before {
  content: "\ea55";
}

.nc-icon.nc-tail-left:before {
  content: "\ea56";
}

.nc-icon.nc-preferences:before {
  content: "\ea57";
}

.nc-icon.nc-settings-gear-64:before {
  content: "\ea58";
}

.nc-icon.nc-metrics:before {
  content: "\ea59";
}

.nc-icon.nc-ui-04:before {
  content: "\ea5a";
}

.nc-icon.nc-single-03:before {
  content: "\ea5b";
}

.nc-icon.nc-multiple:before {
  content: "\ea5c";
}

.nc-icon.nc-list-bullet:before {
  content: "\ea5d";
}

.nc-icon.nc-notification-69:before {
  content: "\ea5e";
}

.nc-icon.nc-road-sign-right:before {
  content: "\ea5f";
}

.nc-icon.nc-log-in:before {
  content: "\ea60";
}

.nc-icon.nc-log-out:before {
  content: "\ea61";
}

.nc-icon.nc-logout:before {
  content: "\ea62";
}

.nc-icon.nc-alert-circle-que:before {
  content: "\ea63";
}

.nc-icon.nc-headset:before {
  content: "\ea64";
}

.nc-icon.nc-key-26:before {
  content: "\ea65";
}

.nc-icon.nc-badge-13:before {
  content: "\ea66";
}

.nc-icon.nc-time:before {
  content: "\ea67";
}

.nc-icon.nc-link-69:before {
  content: "\ea68";
}

.nc-icon.nc-send-2:before {
  content: "\ea69";
}

.nc-icon.nc-simple-remove:before {
  content: "\ea6a";
}

.nc-icon.nc-print:before {
  content: "\ea6b";
}

.nc-icon.nc-add-29:before {
  content: "\ea6c";
}

.nc-icon.nc-map:before {
  content: "\ea6d";
}

.nc-icon.nc-alert-i:before {
  content: "\ea6e";
}

.nc-icon.nc-check-2:before {
  content: "\ea6f";
}

.nc-icon.nc-single-folded:before {
  content: "\ea72";
}

.nc-icon.nc-language:before {
  content: "\ea73";
}

.nc-icon.nc-truck:before {
  content: "\ea74";
}

.nc-icon.nc-delivery-2:before {
  content: "\ea75";
}

.nc-icon.nc-truck-front:before {
  content: "\ea76";
}

.nc-icon.nc-truck-front-o:before {
  content: "\ea77";
}

.nc-icon.nc-trolley-o:before {
  content: "\ea78";
}

.nc-icon.nc-trolley:before {
  content: "\ea79";
}

