// Pattern
// provided and maintained by CD Apps UX
// -------------------------------------
// copyright (c) 2016 Watchtower Bible & Tract Society of New York, Inc.
// -----------------
// Navigation
// -----------------
// Top Navigation
.top-nav {
  flex: none;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 48px;
  background-color: $top-nav-background-color;
  color: $top-nav-text-color;

  &__branding {
    display: inline-flex;
    text-decoration: none;
    border: none;
  }

  &__branding-label {
    display: flex;
    cursor: pointer;
    background-color: $top-nav-branding-background-color;
  }

  @media (min-width: $navigation-breakpoint) {
    &__branding-label--has-side-nav {
      width: 220px;
    }
  }

  &__branding--side-nav-is-open {
    opacity: .7;
  }

  &__hamburger {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: $top-nav-branding-background-color;
    height: 100%;
    transition: margin-left 0.3s ease;
    transform: translateZ(0); // force GPU acceleration
    padding-left: 12px;
    padding-right: 12px;
    justify-content: space-around;
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
  }

  &__logo__img {
    max-height: 100%;
  }

  &__extended-branding {
    flex-basis: 120px;
  }

  &__primary-items {
    display: flex;
  }

  &__title {
    align-self: center;
    font-weight: normal;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.05rem;
    display: inline-block;
    color: $top-nav-title-color;
  }

  &__items {
    align-self: flex-end;
    margin-left: 1rem;
  }

  &__item {
    list-style-type: none;
    text-transform: uppercase;
    display: inline-flex;
  }

  &__link {
    display: block;
    text-decoration: none;
  }

  &__link,
  &__link:visited,
  &__link:focus,
  &__link:hover {
    font-size: 0.85rem;
    padding: .5rem 1rem;
    color: $top-nav-link-color;
    text-decoration: none;
  }

  &__item:hover {
    background-color: $top-nav-link-hover-color;
  }
  &__item--active {
    background-color: $top-nav-link-active-color;
  }
  &__secondary-items {
    flex-shrink: 0;
    justify-content: flex-end;
    align-self: center;
    padding-right: 0.5rem;
  }
  &__secondary-items-container {
    justify-content: flex-end;
    align-self: center;
    display: flex;
  }
  &__aside {
    display: flex;
    align-items: center;
  }
  &__profile {
    display: flex;
    align-items: center;
    padding-right: 0;
  }
  &__profile-image {
    margin-right: -0.5rem;
  }
  p.top-nav__profile-text {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
  }

  &__language-switcher {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }
}

// Side Navigation
// To implement side navigation it and the body of content must be laid out within a flexbox container
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__content {
    display: flex;
    flex: 1 0 auto;
  }

  &__main {
    flex-grow: 1;
    max-width: 100%;

    &--padded {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__footer {
    flex: none;
    background-color: get-color(gray, 150);
    color: get-color(gray, 1000);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

// Side nav
.side-nav {
  min-width: 220px;
  max-width: 220px;
  min-height: 100%;
  transition: min-width 0.3s ease, max-width 0.3s ease; // need to set transition on both min and max width to prevent IE bug
  transform: translateZ(0); // force GPU acceleration
  padding: 20px 0 0 0;
  background-color: $side-nav-background-color;
  color: $side-nav-text-color;
  box-sizing: border-box;

  &__section {
    transition: opacity 0.3s linear;
    transform: translateZ(0); // force GPU acceleration
  }

  &__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 14px;
    border-color: $side-nav-background-color;
    border-width: 0 10px 10px 10px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    margin-top: -28px;
    margin-bottom: 0;
    transition: opacity 0.3s linear;
    transform: translateZ(0); // force GPU acceleration
  }

  &__items {
    font-size: 0.9rem;
    margin: 0;
    padding-bottom: .75rem;
  }

  &__item {
    list-style-type: none;
    padding: 0rem .3rem 0 1rem;
  }

  &__item--active {
    border-left: solid 5px $side-nav-selected-border-color;
    background-color: $side-nav-selected-color;
  }

  &__item--active &__link {
    margin-left: -5px;
  }

  &__header-container {
    // no current styles
  }

  &__header {
    font-weight: bold;
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    margin-left: 1rem;
  }

  &__sub-section {
    // no current styles
  }

  &__sub-header {
    text-transform: $side-nav-subheader-text-transform;
    color: $side-nav-subheader-color;
    font-size: $side-nav-subheader-font-size;
    padding-left: $side-nav-subheader-padding-left;
    margin-top: $side-nav-subheader-margin-top;
  }

  &__link {
    display: block;
    text-decoration: none;
    line-height: 1.7rem;
    padding-left: 5px;
    color: $side-nav-link-color;
  }

  &__link:hover, &__link:focus {
    color: $side-nav-link-color;
    text-decoration: none;
  }

  &__item:hover, &__link:focus {
    color: $side-nav-link-color;
    background-color: $side-nav-hover-color;
    transition: background-color 0.8s ease;
    transform: translateZ(0); // force GPU acceleration
  }

  &__is-collapsed {
      display: none;
  }

  &__is-collapsed:checked ~ .layout__content .side-nav {
    min-width: 0px;
    max-width: 0px;
  }

  &__is-collapsed:checked ~ .layout__content .side-nav .side-nav__section {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    margin-left: -20px;
  }

  &__is-collapsed:checked ~ .layout__content .side-nav .side-nav__link {
    visibility: hidden;
    opacity: 0;
    padding: 0;
  }

  &__is-collapsed:checked ~ .layout__content .side-nav .side-nav__arrow {
    visibility: hidden;
    opacity: 0;
  }

  &__is-collapsed:checked ~ ptrn-top-nav .top-nav__hamburger, &__is-collapsed:checked ~ .top-nav .top-nav__hamburger {
    margin-left: -20px;
  }
}

.site__dimmer {
  display: none; // dimmer for mobile side nav not needed at desktop
}

// Breadcrumbs
.breadcrumbs {
  background-color: none;
  list-style: none;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  &__breadcrumb {
    display: inline-block;
  }

  &__breadcrumb-link {
    text-decoration: none;
  }

  &__breadcrumb--active {
    color: $breadcrumb-active-color;
  }
}

.breadcrumbs__breadcrumb+.breadcrumbs__breadcrumb::before {
  content: "/\00a0";
  color: $breadcrumb-separator-color;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.clickable {
  cursor: pointer;
}

.scroll-to-top {
  cursor: pointer;
  background-color: $scroll-to-top-background-color;
  border-radius: 50%;
  position: fixed;
  width: 32px;
  height: 32px;
  right: 1rem;
  bottom: 1rem;
  padding: 8px 10px 12px 10px;
  z-index: 1;
}

// Breakpoint adjustments from desktop to mobile
@media (max-width: $navigation-breakpoint) {
  .side-nav {
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    width: 260px;
    min-width: 260px;
    max-width: 260px;
    min-height: unset;
    height: calc(100% - 48px); // height of top nav
    top: 48px;
    left: 0;
  }

  .has-side-nav .side-nav__is-collapsed:not(:checked) ~ .layout__content .layout__main {
    position: fixed;
    z-index: 0;
    top: 48px;
    left: 0;
  }

  .layout__main {
    margin-top: 48px;
  }

  .top-nav {
    position: fixed;
    width: 100%;
    z-index: 200;
  }

  .top-nav__branding-label--has-side-nav {
    width: auto;
  }

  .top-nav__items {
    display: none;
  }

  .top-nav__profile-text {
    text-align: right;
  }

  .has-side-nav .side-nav__is-collapsed:not(:checked) ~ .layout__content .site__dimmer {
    background-color: #000;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 50;
    transform: translateZ(0); // force GPU acceleration
    opacity: 0.8;
    transition-delay: 0s;
    display: block;
    top: 0;
    left: 0;
  }
}
