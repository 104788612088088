@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Roboto-100/Roboto-100.eot');
  src: url('../fonts/Roboto-100/Roboto-100.eot?#iefix') format('embedded-opentype'),
       local('Roboto Thin'),
       local('Roboto-100'),
       url('../fonts/Roboto-100/Roboto-100.woff2') format('woff2'),
       url('../fonts/Roboto-100/Roboto-100.woff') format('woff'),
       url('../fonts/Roboto-100/Roboto-100.ttf') format('truetype'),
       url('../fonts/Roboto-100/Roboto-100.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Roboto-300/Roboto-300.eot');
  src: url('../fonts/Roboto-300/Roboto-300.eot?#iefix') format('embedded-opentype'),
       local('Roboto Light'),
       local('Roboto-300'),
       url('../fonts/Roboto-300/Roboto-300.woff2') format('woff2'),
       url('../fonts/Roboto-300/Roboto-300.woff') format('woff'),
       url('../fonts/Roboto-300/Roboto-300.ttf') format('truetype'),
       url('../fonts/Roboto-300/Roboto-300.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Roboto-regular/Roboto-regular.eot');
  src: url('../fonts/Roboto-regular/Roboto-regular.eot?#iefix') format('embedded-opentype'),
       local('Roboto'),
       local('Roboto-regular'),
       url('../fonts/Roboto-regular/Roboto-regular.woff2') format('woff2'),
       url('../fonts/Roboto-regular/Roboto-regular.woff') format('woff'),
       url('../fonts/Roboto-regular/Roboto-regular.ttf') format('truetype'),
       url('../fonts/Roboto-regular/Roboto-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Roboto-500/Roboto-500.eot');
  src: url('../fonts/Roboto-500/Roboto-500.eot?#iefix') format('embedded-opentype'),
       local('Roboto Medium'),
       local('Roboto-500'),
       url('../fonts/Roboto-500/Roboto-500.woff2') format('woff2'),
       url('../fonts/Roboto-500/Roboto-500.woff') format('woff'),
       url('../fonts/Roboto-500/Roboto-500.ttf') format('truetype'),
       url('../fonts/Roboto-500/Roboto-500.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Roboto-700/Roboto-700.eot');
  src: url('../fonts/Roboto-700/Roboto-700.eot?#iefix') format('embedded-opentype'),
       local('Roboto Bold'),
       local('Roboto-700'),
       url('../fonts/Roboto-700/Roboto-700.woff2') format('woff2'),
       url('../fonts/Roboto-700/Roboto-700.woff') format('woff'),
       url('../fonts/Roboto-700/Roboto-700.ttf') format('truetype'),
       url('../fonts/Roboto-700/Roboto-700.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/Roboto-900/Roboto-900.eot');
  src: url('../fonts/Roboto-900/Roboto-900.eot?#iefix') format('embedded-opentype'),
       local('Roboto Black'),
       local('Roboto-900'),
       url('../fonts/Roboto-900/Roboto-900.woff2') format('woff2'),
       url('../fonts/Roboto-900/Roboto-900.woff') format('woff'),
       url('../fonts/Roboto-900/Roboto-900.ttf') format('truetype'),
       url('../fonts/Roboto-900/Roboto-900.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/Roboto-100italic/Roboto-100italic.eot');
  src: url('../fonts/Roboto-100italic/Roboto-100italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Thin Italic'),
       local('Roboto-100italic'),
       url('../fonts/Roboto-100italic/Roboto-100italic.woff2') format('woff2'),
       url('../fonts/Roboto-100italic/Roboto-100italic.woff') format('woff'),
       url('../fonts/Roboto-100italic/Roboto-100italic.ttf') format('truetype'),
       url('../fonts/Roboto-100italic/Roboto-100italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Roboto-300italic/Roboto-300italic.eot');
  src: url('../fonts/Roboto-300italic/Roboto-300italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Light Italic'),
       local('Roboto-300italic'),
       url('../fonts/Roboto-300italic/Roboto-300italic.woff2') format('woff2'),
       url('../fonts/Roboto-300italic/Roboto-300italic.woff') format('woff'),
       url('../fonts/Roboto-300italic/Roboto-300italic.ttf') format('truetype'),
       url('../fonts/Roboto-300italic/Roboto-300italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Roboto-italic/Roboto-italic.eot');
  src: url('../fonts/Roboto-italic/Roboto-italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Italic'),
       local('Roboto-italic'),
       url('../fonts/Roboto-italic/Roboto-italic.woff2') format('woff2'),
       url('../fonts/Roboto-italic/Roboto-italic.woff') format('woff'),
       url('../fonts/Roboto-italic/Roboto-italic.ttf') format('truetype'),
       url('../fonts/Roboto-italic/Roboto-italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/Roboto-500italic/Roboto-500italic.eot');
  src: url('../fonts/Roboto-500italic/Roboto-500italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Medium Italic'),
       local('Roboto-500italic'),
       url('../fonts/Roboto-500italic/Roboto-500italic.woff2') format('woff2'),
       url('../fonts/Roboto-500italic/Roboto-500italic.woff') format('woff'),
       url('../fonts/Roboto-500italic/Roboto-500italic.ttf') format('truetype'),
       url('../fonts/Roboto-500italic/Roboto-500italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Roboto-700italic/Roboto-700italic.eot');
  src: url('../fonts/Roboto-700italic/Roboto-700italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Bold Italic'),
       local('Roboto-700italic'),
       url('../fonts/Roboto-700italic/Roboto-700italic.woff2') format('woff2'),
       url('../fonts/Roboto-700italic/Roboto-700italic.woff') format('woff'),
       url('../fonts/Roboto-700italic/Roboto-700italic.ttf') format('truetype'),
       url('../fonts/Roboto-700italic/Roboto-700italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('../fonts/Roboto-900italic/Roboto-900italic.eot');
  src: url('../fonts/Roboto-900italic/Roboto-900italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Black Italic'),
       local('Roboto-900italic'),
       url('../fonts/Roboto-900italic/Roboto-900italic.woff2') format('woff2'),
       url('../fonts/Roboto-900italic/Roboto-900italic.woff') format('woff'),
       url('../fonts/Roboto-900italic/Roboto-900italic.ttf') format('truetype'),
       url('../fonts/Roboto-900italic/Roboto-900italic.svg#Roboto') format('svg');
}
