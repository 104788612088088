/* 
   Posts 
------------------------------------------------------------------- */

.post {margin-bottom: 24px;}

.post a {text-decoration: none;}
.post a:hover, .post-body a {text-decoration: underline;}

.post-title {border-bottom: 1px solid #E5E5E5;}

.post-date {
	color: $gray-900;
	font-size: 0.9em;
	text-align: right;
	float: right; 
	margin-bottom: 0; 
	margin-left: 10px; 
	margin-top: 0;
}
.post-date a {color: $link-color;}

.post-body {padding-top: 12px;}

.post-meta {
	background: #FCFCFC;
	border: 1px solid $gray-100;
	color: $gray-900;
	font-size: 0.9em;
	padding: 6px 10px;
}
.post-meta a {color: $link-color; }
.post-meta a:hover {color: $link-hover-color;}
