/*!
 * BootstrapValidator (http://bootstrapvalidator.com)
 * The best jQuery plugin to validate form fields. Designed to use with Bootstrap 3
 *
 * @version     v0.5.0, built on 2014-07-14 4:31:02 PM
 * @author      https://twitter.com/nghuuphuoc
 * @copyright   (c) 2013 - 2014 Nguyen Huu Phuoc
 * @license     MIT
 */

.bv-form {
  .help-block {
    margin-bottom: 0;
  }
  .tooltip-inner {
    text-align: left;
  }
}

.nav-tabs li {
  &.bv-tab-success > a {
    color: #3c763d;
  }
  &.bv-tab-error > a {
    color: #a94442;
  }
}

.form-control-feedback {
  right: 1em;
}

select + .form-control-feedback {
  right: 1em;
}
