#fountainG {
    position: relative;
    width: 13.2rem;
    height: 1.6rem;
    margin: 1rem auto auto
}
.fountainG {
    position: absolute;
    top: 0;
    background-color: $primary;
    width: 1.3rem;
    height: 1.3rem;
    animation-name: bounce_fountainG;
    -o-animation-name: bounce_fountainG;
    -ms-animation-name: bounce_fountainG;
    -webkit-animation-name: bounce_fountainG;
    -moz-animation-name: bounce_fountainG;
    animation-duration: 1.035s;
    -o-animation-duration: 1.035s;
    -ms-animation-duration: 1.035s;
    -webkit-animation-duration: 1.035s;
    -moz-animation-duration: 1.035s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    transform: scale(.3);
    -o-transform: scale(.3);
    -ms-transform: scale(.3);
    -webkit-transform: scale(.3);
    -moz-transform: scale(.3);
    border-radius: 1rem;
    -o-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem
}
#fountainG_1 {
    left: 0;
    animation-delay: 416ms;
    -o-animation-delay: 416ms;
    -ms-animation-delay: 416ms;
    -webkit-animation-delay: 416ms;
    -moz-animation-delay: 416ms
}
#fountainG_2 {
    left: 1.6rem;
    animation-delay: .5225s;
    -o-animation-delay: .5225s;
    -ms-animation-delay: .5225s;
    -webkit-animation-delay: .5225s;
    -moz-animation-delay: .5225s
}
#fountainG_3 {
    left: 3.3rem;
    animation-delay: 619ms;
    -o-animation-delay: 619ms;
    -ms-animation-delay: 619ms;
    -webkit-animation-delay: 619ms;
    -moz-animation-delay: 619ms
}
#fountainG_4 {
    left: 4.9rem;
    animation-delay: .7255s;
    -o-animation-delay: .7255s;
    -ms-animation-delay: .7255s;
    -webkit-animation-delay: .7255s;
    -moz-animation-delay: .7255s
}
#fountainG_5 {
    left: 6.6rem;
    animation-delay: 832ms;
    -o-animation-delay: 832ms;
    -ms-animation-delay: 832ms;
    -webkit-animation-delay: 832ms;
    -moz-animation-delay: 832ms
}
#fountainG_6 {
    left: 8.2rem;
    animation-delay: .9385s;
    -o-animation-delay: .9385s;
    -ms-animation-delay: .9385s;
    -webkit-animation-delay: .9385s;
    -moz-animation-delay: .9385s
}
#fountainG_7 {
    left: 9.9rem;
    animation-delay: 1.035s;
    -o-animation-delay: 1.035s;
    -ms-animation-delay: 1.035s;
    -webkit-animation-delay: 1.035s;
    -moz-animation-delay: 1.035s
}
#fountainG_8 {
    left: 11.5rem;
    animation-delay: 1.1415s;
    -o-animation-delay: 1.1415s;
    -ms-animation-delay: 1.1415s;
    -webkit-animation-delay: 1.1415s;
    -moz-animation-delay: 1.1415s
}
@keyframes bounce_fountainG {
    0% {
        transform: scale(1);
        background-color: $primary
    }
    100% {
        transform: scale(.3);
        background-color: #fff
    }
}
@-o-keyframes bounce_fountainG {
    0% {
        -o-transform: scale(1);
        background-color: $primary
    }
    100% {
        -o-transform: scale(.3);
        background-color: #fff
    }
}
@-ms-keyframes bounce_fountainG {
    0% {
        -ms-transform: scale(1);
        background-color: $primary
    }
    100% {
        -ms-transform: scale(.3);
        background-color: #fff
    }
}
@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: scale(1);
        background-color: $primary
    }
    100% {
        -webkit-transform: scale(.3);
        background-color: #fff
    }
}
@-moz-keyframes bounce_fountainG {
    0% {
        -moz-transform: scale(1);
        background-color: $primary
    }
    100% {
        -moz-transform: scale(.3);
        background-color: #fff
    }
}