@media (min-width: 768px){
	.dropdown-menu.dicon-only {
		min-width: 180px;
	}
	.dropdown-menu.dicon-only li {
	    float: left;
	    font-size: 80%;
	    width: 50%;
	    overflow: hidden;
	    text-align: center;
	    vertical-align: middle;
	}
	
	.dropdown-menu.dicon-only li.full-width {
		width: 100%;
	}
	.dropdown-menu.dicon-only li.dropdown-header{
		font-size: 16px;
		width: 100%;
	}
	.dropdown-menu.dicon-only li.divider{
		float: none;
		clear: both;
		width: inherit;
	}
	.dropdown-menu.dicon-only li a{
		padding: 3px;
	}
	.dropdown-menu.dicon-only li .nc-icon{
		display: block;
		font-weight: 800;
	}
}