.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  label {
    padding-left: 25px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin-left: -25px;
  }
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: relative;
  margin-top: 6px;
  margin-right: 4px;
  vertical-align: top;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    @include transition(240ms);
  }

  &:before {
    position: absolute;
    left: 0;
    top: -3px;
    background-color: $primary;
    transform: scale(0);
  }

  &:after {
    position: relative;
    top: -3px;
    border: 2px solid $gray-500;
  }

  &:checked:before {
    transform: scale(0.5);
  }

  &:disabled:checked:before {
    background-color: $gray-100;
  }

  &:checked:after {
    border-color: $primary;
  }

  &:disabled:after,
  &:disabled:checked:after {
    border-color: $gray-100;
  }
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  border: none;
  margin-bottom: -4px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus:after {
    border-color: $primary;
  }

  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid $gray;
    border-radius: 2px;
    @include transition(240ms);
  }

  &:checked:before {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
  }

  &:checked:after {
    background-color: $primary;
    border-color: $primary;
  }

  &:disabled:after {
    border-color: $gray-100;
  }

  &:disabled:checked:after {
    background-color: $gray-100;
    border-color: transparent;
  }
}
