@import '../bootswatch-flatly/variables';

@mixin modal-hdr-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  .close, a {
    color: darken($text-color, 10%);
  }
}

.modal-header-default {
  @include modal-hdr-variant($btn-default-bg, $btn-default-border, $btn-default-color);
}

.modal-header-info {
  @include modal-hdr-variant($btn-info-bg, $btn-info-border, $btn-info-color);
}

.modal-header-danger {
  @include modal-hdr-variant($btn-danger-bg, $btn-danger-border, $btn-danger-color);
}

.modal-footer-default {
  @include modal-hdr-variant($btn-default-bg, $btn-default-border, $btn-default-color);

}

.modal-footer-info {
  @include modal-hdr-variant($btn-info-bg, $btn-info-border, $btn-info-color);

}

.modal-footer-danger {
  @include modal-hdr-variant($btn-danger-bg, $btn-danger-border, $btn-danger-color);

}
