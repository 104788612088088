body {
    //padding-top: 90px;
}

.btn-inverse {
  @include button-variant($primary, $primary);
}

.page-card {
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 0;
    color: $body-color;
    background: #FFF;
    padding: 30px;
}

.card {
  word-wrap: normal;
}

.navbar-default .badge {
    background-color: $danger;
    color: $white;
}

@mixin bs-callout($color, $bgcolor) {
  display: block;
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border: 1px solid;
  border-left-width: 5px;
  border-color: lighten($color, 25%);
  border-left-color: $color;
  background-color: $bgcolor;
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    color: $color;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code, .highlight {
    background-color: #fff;
  }
}

.bs-callout-primary {
  @include bs-callout($primary, lighten($primary, 45%));
}

.bs-callout-danger {
  @include bs-callout($danger, lighten($danger, 30%));
}

.bs-callout-warning {
  @include bs-callout($warning, lighten($warning, 50%));
}

.bs-callout-info {
  @include bs-callout($info, lighten($info, 40%));
}

.bs-callout-success {
  @include bs-callout($success, lighten($success, 40%));
}

/*loading icon */
.loading,
.loading > div {
    position: relative;
    box-sizing: border-box
}
.loading {
    width: 32px;
    height: 32px;
    display: block;
    font-size: 0;
    color: $primary;
}
.loading.pos-center {
    margin: auto;
}
.loading.page {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
}
.loading > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 32px;
    height: 32px;
    background: transparent;
    border-width: 2px;
    border-bottom-color: transparent;
    border-radius: 100%;
    -webkit-animation: rotation .75s linear 0.00s infinite;
    animation: rotation .75s linear 0.00s infinite;
}
.loading.small {
    width: 16px;
    height: 16px
}
.loading.small > div {
    width:16px;
    height:16px;
    border-width:1px
}
.loading.lrg {
    width: 64px;
    height: 64px
}
.loading.lrg > div {
    width: 64px;
    height: 64px;
    border-width: 4px
}
.loading.xlg {
    width: 96px;
    height: 96px
}
.loading.xlg > div {
    width: 96px;
    height: 96px;
    border-width: 6px
}
@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotation {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: $gray-100;
}

.site-footer {
  background-color: $gray-800;
  padding: 40px 0;
  color: #fff;
  margin-top: 30px;
}

.site-footer a {
  color: #fff;
  opacity: 0.6;
}

.site-footer a:hover {
  color: #fff;
  opacity: 1;
}

.navbar-nocol .navbar-nav {
  margin: 0;
}
.navbar-nocol .navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-nocol{
  min-height: 40px;
}

/* Print fixes */
@media print{
  body {
    padding: 0px !important;
  }

 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4{
    margin-top: 0px;
  }

  a[href]:after {
    content: "";
  }
}

// Footer
footer {
    background: $gray-800;
    margin-top: 2em;
    padding-top: 2em;
    padding-bottom: 2em;

    p {
        padding: 2px 5px;
        margin: 0 1em;
    }
    
    p, a, a:hover, a:focus, span.version {
        color: $gray-100;
        font-size: 0.75rem;
        margin-bottom: .25em;
    }

    .guid {
        font-family: Consolas, "Roboto Mono", "Courier New", Courier, monospace; 
        padding: 2px 5px; 
        background-color: lighten($gray-800, 50%); 
        margin: 0 1em; 
        white-space: nowrap; 
        color: $gray-800; 
        display: inline-block;
    }

    #footer-resources {
        text-align: right;

        i {
            color: $gray-100;
            font-size: 1.5rem;
            margin-left: .5em;
        }
    }
}


.container {
  @media (min-width: 1920px) {
    width: 1870px;
  }
}


@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}